import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import OurHistory from '../components/AboutUs/OurHistory'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
import HowItWork from '../components/AboutUs/HowItWork'
import TeamMembers from '../components/AboutUs/TeamMembers'
import Testimonials from '../components/AboutUs/Testimonials'
import Partner from '../components/AboutUs/Partner'
import StartProject from '../components/Common/StartProject';
 
const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="À propos" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="À propos" 
            />
            <AboutUsContent />
            {/* <OurHistory /> */}
            {/* <WhyChooseUs /> */}
            {/* <HowItWork />
            <TeamMembers />
            <Testimonials />
            <Partner /> */}
            <Footer />
        </Layout>
    );
}

export default AboutUs;